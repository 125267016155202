<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">Gestión de Proveedores</h3>
          </div>
          <div class="col-md-6">

          </div>
        </div>

        <div class="white-content-general mb-4">
          <div class="row">
            <div class="col-md-4">
              <p class="lh-m mb-0">

              </p>
            </div>
            <div class="col-md-8">
              <button @click="agregar" class="btn btn-general btn-primary float-right">
                <span class="icon-plus"></span> Agregar
              </button>
            </div>
          </div>
        </div>
        <TablaFiltro :data="proveedores" :columns="columnas" @edit="editar"
                     @delete="eliminar"/>
      </div>
    </div>
  </div>
</template>
<script>
import TablaFiltro from "../../../../components/TablaFiltro.vue";

export default {
  name: "GestionProveedores",
  components: {TablaFiltro},
  data() {
    return {
      columnas: [
        {campo: "tipoProveedor", tipo: "String", nombre: "Tipo Proveedor"},
        {campo: "nombreProveedor", tipo: "String", nombre: "Nombre Proveedor"},
        {campo: "direccion", tipo: "String", nombre: "Dirección"},
        {campo: "telefono", tipo: "String", nombre: "Teléfono"},
        {campo: "bodegas", tipo: "Arreglo", nombre: "Bodegas"}
      ],

    }
  },
  async created() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$store.dispatch("auth/logout");
      return false;
    }
    await this.consultar();
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },

    async consultar() {
      this.loaderSpinner();
      try {
        await this.$store.dispatch("proveedores/consultar");
      } catch (error) {
        alert("No se pudo realizar la consulta");
      } finally {
        this.loader.hide();
      }
    },
    async agregar() {
      await this.$store.commit("proveedores/setProveedorSeleccionado", null);
      this.$router.push({name: "ProveedorForm"});
    },
    async editar(proveedor) {
      await this.$store.commit(
          "proveedores/setProveedorSeleccionado",
          proveedor
      );
      this.$router.push({name: "ProveedorForm"});
    },
    async eliminar(id) {
      this.$swal({
        text: "¿Está seguro de eliminar la información?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loaderSpinner();
          try {
             const resp = await this.$store.dispatch("proveedores/eliminar", {id: id});
             if(resp.success) {
               this.$swal({
                 text: "Registro eliminado con éxito.",
                 icon: "success",
               });
             }else {
               this.$swal({
                 text: resp.data,
                 icon: "warning",
               });
             }

          } catch (error) {
            this.$swal("", error.message, "error");
          } finally {
            this.loader.hide();
          }
          await this.consultar();
        }
      });
    },
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
          ? this.$store.getters["auth/isLogged"]
          : false;
    },
    proveedores() {
      return this.$store.getters["proveedores/proveedores"]
          ? this.$store.getters["proveedores/proveedores"]
          : [];
    },
  }
};
</script>
<style></style>
