import axios from "axios";

const state = {
    usuarioProveedor: null,
};

const getters = {
    usuarioProveedor: (state) => state.usuarioProveedor
};

const mutations = {
    setUsuarioProveedor: (state, usuarioProveedor) => {
        state.usuarioProveedor = usuarioProveedor;
    },
};

const actions = {

    async consultarProveedorUsuario({ commit }, data) {
        try {
            const res = await axios.get(`/v1/usuario-proveedores/${data.idUsuario}/usuario`);
            commit("setUsuarioProveedor", res?.data?.result);
        } catch (error) {
            commit("setUsuarioProveedor", []);
            return error;
        }
    },

    async eliminar({ commit }, data) {
        try {
            await axios.delete(`/v1/usuario-proveedores/${data.id}`);
            commit("setUsuarioProveedor", []);
        } catch (error) {
            return error;
        }
    },
    async consultarTodos() {
        try {
            const resp = await axios.get(`/v1/usuario-proveedores/all`);
            return {
                success: true,
                data: resp.data,
            }
        } catch (error) {
            return {
                success: false,
                data: error.response.data.errors
            }
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
