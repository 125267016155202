import { createStore } from 'vuex'
import global from './modules/global'
import auth from "./modules/auth";
import equipos from "./modules/equipos";
import proveedores from "./modules/proveedores";
import bodegas from "./modules/bodegas";
import solicitudes from "./modules/solicitudes";
import solicitudesProveedor from "./modules/solicitudesProveedor";
import usuarios from './modules/usuarios';
import procesos from './modules/procesos';
import solicitudesRechazadas from "./modules/solicitudesRechazadas";
import archivosSolicitudes from './modules/archivosSolicitudes';
import regularizaciones from './modules/regularizaciones';
import ruta from './modules/ruta';
import usuariosBodega from './modules/usuariosBodega';
import equiposProveedor from './modules/equiposProveedor';
import solicitudesProveedorAtendidas from './modules/solicitudesProveedorAtendidas';
import actividadesMantenimiento from './modules/actividadesMantenimiento';
import solicitudesTecnico from './modules/solicitudesTecnico';
import bodegaGerencia from './modules/bodegaGerencia';
import rutero from './modules/rutero';
import analisis from './modules/analisis';
import solicitudesTransporte from './modules/solicitudesTransporte';
import eventos from './modules/eventos';
import reparaciones from './modules/reparaciones';
import adjuntos from './modules/adjuntos';
import movimientos from './modules/movimientos';
import cartera from './modules/cartera';
import objetivosVisita from './modules/objetivosVisita';
import perfil from './modules/perfil';
import encuesta from './modules/encuesta';
import notificaciones from './modules/notificaciones';
import bajas from './modules/bajas';
import roles from './modules/roles';
import rutaHome from './modules/rutaHome';
import actualizacion from './modules/actualizacion';
import pagination from './modules/pagination';
import menu from './modules/menu';
import filtros from './modules/filtros';
import filtro from './modules/filtro';
import atencionAdjunto from './modules/atencionAdjunto';
import variables from './modules/variables';
import listaNegra from './modules/listaNegra';
import bajasBloque from './modules/bajasBloque';
import exportar from './modules/exportar';
import waiting from './modules/waiting';
import waitingArchivo from './modules/waitingarchivo';
import commons from './modules/commons';
import bajaSolicitadaTecnico from './modules/bajaSolicitadaTecnico';
import traslados from './modules/traslados'
import trasladosAprobacion from "@/store/modules/trasladosAprobacion";
import trasladosRecibir from "@/store/modules/trasladosRecibir";
import reportes from './modules/reportes';
import usuariosProveedor from './modules/usuariosProveedor';

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    global,
    auth,
    equipos,
    proveedores,
    bodegas,
    solicitudes,
    solicitudesProveedor,
    usuarios,
    procesos,
    solicitudesRechazadas,
    archivosSolicitudes,
    regularizaciones,
    ruta,
    usuariosBodega,
    equiposProveedor,
    solicitudesProveedorAtendidas,
    actividadesMantenimiento,
    solicitudesTecnico,
    bodegaGerencia,
    rutero,
    analisis,
    solicitudesTransporte,
    eventos,
    reparaciones,
    adjuntos,
    movimientos,
    cartera,
    objetivosVisita,
    perfil,
    encuesta,
    notificaciones,
    bajas,
    roles,
    rutaHome,
    actualizacion,
    pagination,
    menu,
    filtros,
    filtro,
    atencionAdjunto,
    variables,
    listaNegra,
    bajasBloque,
    exportar,
    waiting,
    waitingArchivo,
    commons,
    bajaSolicitadaTecnico,
    traslados,
    trasladosAprobacion,
    trasladosRecibir,
    reportes,
    usuariosProveedor
  }
})
