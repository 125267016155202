<template>
  <div
      class="col-md-12 grid-margin stretch-card grid-margin"
  >
    <div class="card">
      <div class="card-body">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">
              {{ equipoSeleccionado ? "Editar equipo" : "Agregar equipo" }}
            </h3>
          </div>
        </div>

        <div class="white-content-general mb-4">
          <div class="row">
            <div class="col-lg-12">
              <div class="form-sample">

                <Form
                    @submit="submit"
                    :validation-schema="formValidation"
                    v-slot="{ errors }"
                >
                  <div class="row">
                    <div class="col-lg-12">

                      <div class="row">

                        <div class="col-md-12">
                          <h4 class="text-black">Características</h4>
                          <hr>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="placa">{{ this.$config.labelPlaca }} *</label>
                            <fieldset>
                              <Field
                                  type="text"
                                  name="placa"
                                  id="placa"
                                  class="form-control"
                                  placeholder="Placa"
                                  v-model.trim="formData.placa"
                              />
                              <MensajeValidacion :mensaje="errors.placa"/>
                            </fieldset>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="serie">Serie *</label>
                            <fieldset>
                              <Field
                                  type="text"
                                  name="serie"
                                  class="form-control"
                                  placeholder="Serie"
                                  v-model.trim="formData.serie"
                              />
                              <MensajeValidacion :mensaje="errors.serie"/>
                            </fieldset>
                          </div>
                        </div>


                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="serieAnt">Serie Ant *</label>
                            <fieldset>
                              <Field
                                  type="text"
                                  name="serieAnt"
                                  class="form-control"
                                  placeholder="Serie Ant"
                                  v-model.trim="formData.serieAnt"
                              />
                              <MensajeValidacion :mensaje="errors.serieAnt"/>
                            </fieldset>
                          </div>
                        </div>


                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="numeroTasacion">Número Tasación</label>
                            <fieldset>
                              <Field
                                  type="text"
                                  name="numeroTasacion"
                                  class="form-control"
                                  placeholder="Número Tasación"
                                  v-model.trim="formData.numeroTasacion"
                              />
                              <MensajeValidacion :mensaje="errors.numeroTasacion"/>
                            </fieldset>
                          </div>
                        </div>


                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="activoFijo">Activo Fijo *</label>
                            <fieldset>
                              <Field
                                  type="text"
                                  name="activoFijo"
                                  class="form-control"
                                  placeholder="Activo Fijo"
                                  v-model.trim="formData.activoFijo"
                              />
                              <MensajeValidacion :mensaje="errors.activoFijo"/>
                            </fieldset>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="logo">Categoria *</label>
                            <CustomVariableList
                                variable="INVENTARIO_TIPO_EQUIPO"
                                :selectedOptionValue="formData.tipoEquipo"
                                :selectVariable="handleSelectChangeTipoEquipo"
                            />
                          </div>
                        </div>


                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="subCategoria">Subcategoria *</label>
                            <CustomVariableList
                                variable="INVENTARIO_SUBCATEGORIA"
                                :selectedOptionValue="formData.subCategoria"
                                :selectVariable="handleSelectChangeSubcategoria"
                            />
                            <MensajeValidacion :mensaje="errors.subCategoria"/>
                          </div>

                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="logo">Marca ABI *</label>
                            <CustomVariableList :disabled="equipoSeleccionado!==null"
                                variable="INVENTARIO_MARCA_ABI"
                                :selectedOptionValue="formData.marca"
                                :selectVariable="handleSelectChangeMarca"
                            />
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="logo">Fabricante *</label>
                            <CustomVariableList
                                variable="INVENTARIO_FABRICANTE"
                                :selectedOptionValue="formData.fabricante"
                                :selectVariable="handleSelectChangefabricante"
                            />
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="capacidadCajas"
                            >Capacidad *</label
                            >
                            <fieldset>
                              <Field
                                  type="text"
                                  name="capacidadCajas"
                                  class="form-control"
                                  placeholder="Capacidad Cajas"
                                  v-model.trim="formData.capacidadCajas"
                              />
                              <MensajeValidacion
                                  :mensaje="errors.capacidadCajas"
                              />
                            </fieldset>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="logo">Logo *</label>
                            <CustomVariableList
                                variable="LOGO"
                                :selectedOptionValue="formData.logo"
                                :selectVariable="handleSelectChangeLogo"
                            />
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="logo">Controlador *</label>
                            <CustomVariableList
                                variable="INVENTARIO_CONTROLADOR"
                                :selectedOptionValue="formData.activoControlador"
                                :selectVariable="handleSelectChangeControlador"
                            />
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="activoDescripcion">Activo Descripción *</label>
                            <fieldset>
                              <Field
                                  type="text"
                                  name="activoDescripcion"
                                  id="activoDescripcion"
                                  class="form-control"
                                  placeholder="Activo Descripción"
                                  v-model.trim="formData.activoDescripcion"
                              />
                              <MensajeValidacion :mensaje="errors.activoDescripcion"/>
                            </fieldset>
                          </div>
                        </div>

                        <div class="col-md-12">
                          <h4 class="text-black">Adquisición</h4>
                          <hr>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="fechaAdquisicion"
                            >Fecha Adquisición *</label
                            >
                            <fieldset>
                              <Field
                                  type="date"
                                  name="fechaAdquisicion"
                                  class="form-control"
                                  placeholder="Fecha adquisición"
                                  v-model.trim="formData.fechaAdquisicion"
                              />
                              <MensajeValidacion
                                  :mensaje="errors.fechaAdquisicion"
                              />
                            </fieldset>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="valorAdquisicion"
                            >Valor Adquisición *</label
                            >
                            <fieldset>
                              <Field
                                  type="text"
                                  name="valorAdquisicion"
                                  class="form-control"
                                  placeholder="Valor adquisición"
                                  v-model.trim="formData.valorAdquisicion"
                              />
                              <MensajeValidacion
                                  :mensaje="errors.valorAdquisicion"
                              />
                            </fieldset>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="logo">Moneda *</label>
                            <CustomVariableList
                                variable="INVENTARIO_MONEDA"
                                :selectedOptionValue="formData.moneda"
                                :selectVariable="handleSelectChangeMoneda"
                            />
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="facturaCompra"
                            >Factura Compra *</label
                            >
                            <fieldset>
                              <Field
                                  type="text"
                                  name="facturaCompra"
                                  class="form-control"
                                  placeholder="Factura Compra"
                                  v-model.trim="formData.facturaCompra"
                              />
                              <MensajeValidacion
                                  :mensaje="errors.facturaCompra"
                              />
                            </fieldset>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="guiaCompra"
                            >Guía Compra *</label
                            >
                            <fieldset>
                              <Field
                                  type="text"
                                  name="guiaCompra"
                                  class="form-control"
                                  placeholder="Guía Compra"
                                  v-model.trim="formData.guiaCompra"
                              />
                              <MensajeValidacion
                                  :mensaje="errors.guiaCompra"
                              />
                            </fieldset>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="logo">Repotenciado *</label>
                            <CustomVariableList
                                variable="INVENTARIO_REPOTENCIADO"
                                :selectedOptionValue="formData.repotenciado"
                                :selectVariable="handleSelectChangeRepotenciado"
                            />
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="responsableRepotenciado">Responsable Repotenciado *</label>
                            <fieldset>
                              <Field
                                  type="text"
                                  name="responsableRepotenciado"
                                  class="form-control"
                                  placeholder="Responsable Repotenciado"
                                  v-model.trim="formData.responsableRepotenciado"
                              />
                              <MensajeValidacion :mensaje="errors.responsableRepotenciado"/>
                            </fieldset>
                          </div>
                        </div>


                        <div v-if="mostrarActualizarBodega" class="col-md-12">
                          <h4 class="text-black">Bodega</h4>
                          <hr>
                        </div>

                        <div v-if="mostrarActualizarBodega" class="col-md-6">
                          <div class="form-group">
                            <label>Proveedor</label>

                            <fieldset>
                              <select class="form-control" v-model="formData.proveedor"
                                      @change="handleSelectChangeProveedor">
                                <option value="0" disabled>Seleccione una opción</option>
                                <option
                                    v-for="item in proveedores"
                                    :key="item.id"
                                    :value="item.id"
                                >
                                  {{ item.nombreProveedor }}
                                </option>
                              </select>
                            </fieldset>


                          </div>
                        </div>

                        <div v-if="mostrarActualizarBodega" class="col-md-6">
                          <div class="form-group">
                            <label>Bodega</label>
                            <fieldset>
                              <select class="form-control" v-model="formData.bodega">
                                <option value="0" disabled>Seleccione una opción</option>
                                <option
                                    v-for="item in bodegas"
                                    :key="item.id"
                                    :value="item.id"
                                >
                                  {{ item.nombre }}
                                </option>
                              </select>
                            </fieldset>
                          </div>
                        </div>


                        <div class="col-md-12">
                          <h4 class="text-black">Adjuntos</h4>
                          <hr>
                        </div>

                        <div class="col-md-12">
                          <CustomAdjuntos
                              tipoSolicitud="CREACION_EQUIPO"
                              :uploadFile="uploadFile"
                              columna="6"
                          />
                        </div>

                        <div style="margin-left:15px" class="mb-2" v-if="archivosArray.length > 0">

                          <label for="formFile" class="form-label"
                          >Lista de archivos</label
                          >
                          <ul>
                            <li
                                v-for="(archivo, $index) in archivosArray"
                                :key="$index"
                            >
                              {{ archivo?.name }}
                              <a
                                  href="javascript:void(0)"
                                  class="text-danger"
                                  @click="eliminarArchivo(archivo)"
                              ><i class="ti-close"></i
                              ></a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6" v-if="!equipoSeleccionado">
                          <div class="form-group">
                            <label style="margin-right: 15px"
                            >Ubicación actual
                            </label>
                            <fieldset>
                              <p class="text-muted">
                                {{ equipoSeleccionado?.ubicacion }}
                                {{ equipoSeleccionado?.bodega }}
                              </p>
                            </fieldset>
                          </div>
                        </div>

                        <div class="col-md-6" v-else>
                          <div class="form-group">
                            <label>Ubicación actual</label>
                            <fieldset>
                              <p class="text-muted">
                                {{ equipoSeleccionado.ubicacion }}
                                {{ equipoSeleccionado?.cliente }}
                              </p>
                            </fieldset>
                          </div>
                        </div>
                      </div>
                    </div>

                    <ErrorMessage :mensaje="mensaje" v-if="error"/>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <button
                          type="submit"
                          id="form-submit"
                          class="btn btn-primary btn-general mr-2"
                      >
                        <i class="ti-save"></i> Guardar
                      </button>
                      <button
                          type="button"
                          class="btn btn-secondary btn-general-off"
                          @click="cerrar"
                      >
                        <i class="ti-close"></i> Cerrar
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {Field, Form} from "vee-validate";
import MensajeValidacion from "../../../../components/MensajeValidacion.vue";
import ErrorMessage from "../../../../components/ErrorMessage";
import CustomVariableList from "@/components/CustomVariableList.vue";
import CustomAdjuntos from "@/components/CustomAdjuntos.vue";

export default {
  name: "EquipoForm",
  components: {
    CustomVariableList,
    ErrorMessage,
    Form,
    Field,
    MensajeValidacion,
    CustomAdjuntos,

  },
  data() {
    const formValidation = {
      placa: "required",
      serie: "required",
      capacidadCajas: "required",
      activoFijo: "required",
      serieAnt : "required"
    };
    return {
      formData: {
        placa: "",
        serie: "",
        marca: "",
        modelo: "",
        logo: "",
        anioFabricacion: 0,
        tipoEquipo: "",
        fabricanteGenesis: "",
        statusNeveras: "",
        valorLibros: 0,
        valorComercial: 0,
        statusLibros: "",
        anioAdquisicion: 0,
        capacidadBotellas: 0,
        capacidadCajas: 0,
        capacidadPies: 0,
        activoFijo: "",
        numeroEquipo: "",
        estadoEquipo: "DISPONIBLE",
        adjuntos: [],
        activoFijoAnterior: "",
        subCategoria: "",
        activoDescripcion: "",
        activoControlador: "",
        fechaAdquisicion: "",
        valorAdquisicion: 0.0,
        moneda: "",
        facturaCompra: "",
        guiaCompra: "",
        repotenciado: "",
        fabricante: "",
        responsableRepotenciado: "",
        bodega: 0,
        proveedor: 0,
        serieAnt : "",
        numeroTasacion : ""
      },
      error: false,
      mensaje: "",
      formValidation,
      tipoUbicacion: null,
      codigoCliente: "",
      bodega: 0,
      ubicacion: "",
      mostrarCliente: false,
      mostrarBodega: false,
      modificarUbicacion: false,
      mostrarModificacionUbicacion: false,
      archivosArray: [],
      mostrarActualizarBodega: true
    };
  },
  created() {
    this.$store.dispatch("global/updateIsPreventNavigation", true);
  },
  mounted() {
    if (this.equipoSeleccionado) {
      this.mostrarActualizarBodega = false
      this.formData = {...this.equipoSeleccionado};
      this.consultarBodegasByProveedor();
    }
    this.consultarProveedores();
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultarProveedores() {
      try {
        await this.$store.dispatch("proveedores/consultar");
      } catch (error) {
        alert("No se pudo realizar la consulta");
      }
    },
    async consultarBodegasByProveedor() {
      try {
        await this.$store.dispatch("bodegas/consultarXProveedor", {
          proveedor: this.formData.proveedor,
        });
      } catch (error) {
        alert("No se pudo realizar la consulta");
      }
    },
    uploadFile(file) {
      const selectedFile = file?.target?.files[0];
      if (!selectedFile) return;
      const findedFile = this.archivosArray?.findIndex(
          (item) => item.name === selectedFile.name
      );
      let arraySelectedFiles = [];
      if (findedFile === -1) {
        arraySelectedFiles = [...this.archivosArray, selectedFile];
      } else {
        arraySelectedFiles = [...this.archivosArray];
      }
      this.archivosArray = arraySelectedFiles;

    },
    handleSelectChangeLogo(logo) {
      this.formData.logo = logo;
    },
    handleSelectChangeRepotenciado(repotenciado) {
      this.formData.repotenciado = repotenciado;
    },

    handleSelectChangeMoneda(moneda) {
      this.formData.moneda = moneda;
    },

    handleSelectChangeControlador(controlador) {
      this.formData.activoControlador = controlador;
    },

    handleSelectChangeTipoEquipo(tipo) {
      this.formData.tipoEquipo = tipo;
    },

    handleSelectChangeMarca(marca) {
      this.formData.marca = marca;
    },

    handleSelectChangefabricante(fabricante) {
      this.formData.fabricante = fabricante;
    },

    handleSelectChangeSubcategoria(subCategoria) {
      this.formData.subCategoria = subCategoria;
    },

    eliminarArchivo(archivo) {
      this.archivosArray = this.archivosArray?.filter(
          (item) => item !== archivo
      );

    },
    async submit() {
      if (!this.formData.logo.length) return;
      if (this.equipoSeleccionado) {
        this.formData.cliente = this.equipoSeleccionado?.cliente;
        this.formData.bodega = this.equipoSeleccionado?.bodega;
      }
      this.error = false;
      this.loaderSpinner();
      try {
        if (this.equipoSeleccionado) {
          const data = new FormData();
          for (const key in this.equipoSeleccionado) {
            data.append(key, this.equipoSeleccionado[key]);
          }
          const resp = await this.$store.dispatch("equipos/editarEquipoV2", {
            id: this.equipoSeleccionado.id,
            data: this.formData,
          });
          this.archivosArray?.map((item) => data.append("file", item));
          await this.$store.commit("equipos/setEquipoSeleccionado", null);
          if (resp.success) {
            this.$router.push({name: "Equipos"});
          } else {
            alert(resp.data)
            return;
          }
        } else {
          if (!this.validarArchivos()) {
            return;
          }
          const data = new FormData();
          for (const key in this.formData) {
            data.append(key, this.formData[key]);
          }
          this.archivosArray?.map((item) => data.append("adjuntos", item));
          let resp = await this.$store.dispatch("equipos/crearEquipoV2", data);
          if (resp?.response?.data?.errors[0]) {
            this.error = true;
            this.mensaje = resp?.response?.data?.errors[0];
            return;
          } else {
            this.error = false;
            this.$router.push({name: "Equipos"});
          }

        }
        this.$store.dispatch("global/updateIsPreventNavigation", false);
        this.$router.push({name: "Equipos"});
      } catch (error) {
        this.error = true;
        this.mensaje = "No se pudo guardar la información";
      } finally {
        this.loader.hide();
      }
    },
    cerrar() {
      this.$store.dispatch("global/updateIsPreventNavigation", false);
      this.$router.go(-1);
    },

    validarArchivos() {
      if (this.archivosArray.length < 2) {
        this.mensaje = 'No se han seleccionado los archivos adjuntos'
        this.error = true
        return false
      } else {
        this.mensaje = ''
        this.error = false
        return true
      }
    },
    handleSelectChangeProveedor() {
      this.formData.bodega = 0
      this.consultarBodegasByProveedor()
    }
  },
  computed: {
    equipoSeleccionado() {
      return this.$store.getters["equipos/equipoSeleccionado"]
          ? this.$store.getters["equipos/equipoSeleccionado"]
          : null;
    },
    proveedores() {
      return this.$store.getters["proveedores/proveedores"]
          ? this.$store.getters["proveedores/proveedores"]
          : [];
    },
    bodegas() {
      return this.$store.getters["bodegas/bodegas"]
          ? this.$store.getters["bodegas/bodegas"]
          : [];
    },

  },
};
</script>
