<template>
    <span tabindex="0" @blur="hideOptions">
    <fieldset>
        <label>{{ name }}</label>
        <button class="btn btn-default dropdown-toggle widthInput" type="button" @click="toggleOptions">
            {{ totalSelectedOptions }}
        </button>

        <div v-if="showOptions" class="dropdown-menu-filtros widthDiv">
            <div class="dropdown-item" @click="checkAll">
                <label> TODOS </label>
            </div>
            <div v-for="option in options" :key="option.descripcion" class="dropdown-item " @click.stop>
                <label @click.stop>
                    <input type="checkbox" :name="name" :value="option.descripcion" v-model="selectedValues" />
                    {{ option.descripcion }}
                </label>
            </div>
        </div>
    </fieldset>
</span>
  </template>

  <script>
  export default {
    props: {
        name: {
            type: String,
            default: "",
        },
        baseName: {
            type: String,
            default: "",
        },
        comparator: {
            type: String,
            default: "",
        },
        options: {
            type: Array,
            required: true,
        },
        placeholder: {
            type: String,
            default: "Selecciona opciones",
        },
        instanceId: {
            type: [String, Number],
            required: true,
        }
    },
    data() {
        return {
            selectedValues: [],
            showOptions: false,
        };
    },
    mounted() {
    window.addEventListener('click', this.handleClickOutside);
  },
  beforeUnmount () {
    window.removeEventListener('click', this.handleClickOutside);
  },
    computed: {
        selectedText() {
            if (this.selectedValues.length === 0) return null;
            const selectedOptions = this.options.filter((option) =>
                this.selectedValues.includes(option.descripcion)
            );
            return selectedOptions.map((option) => option.descripcion).join(", ");
        },
        totalSelectedOptions() {
            if (this.selectedValues.length === 0) return;
            return this.selectedValues.length + " seleccionadas"
        }
    },
    methods: {
        toggleOptions() {
            this.showOptions = !this.showOptions;
        },
        checkAll() {
            const selectedValuesLength = this.selectedValues.length;
            const optionsLength = this.options.length;
            if (selectedValuesLength === optionsLength) {
                this.selectedValues = [];
            } else {
                this.selectedValues = this.options.map(option => option.descripcion);
            }

        },
        limpiarFiltros(){
            this.selectedValues = [];
        },
        handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.hideOptions();
      }
    },
    hideOptions() {
      this.showOptions = false;
    }
    },
    watch: {
        selectedValues(newVal) {
            this.$emit('input', { id: this.instanceId, values: newVal, nombreBase: this.baseName, comparator: this.comparator });
        },
    },
  };
  </script>
  <style>
  .widthInput {
    min-width: 208px;
    min-height: 38px;
    width: 100%;
    box-sizing: border-box;
    padding: 0;
    border: 1px solid #141414;
  }
  .widthDiv {
    min-width: 180px;
    width: 85%;
    box-sizing: border-box;
    border-radius: 10px;
  }
  </style>
