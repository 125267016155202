<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">Procesar requerimiento</h3>
          </div>
        </div>
        <div class="white-content-general mb-4">
          <form name="f1" id="formElement">

            <CabeceraRequerimiento/>

            <div class="ml-4 mt-4">
              <h5 class="card-title">Datos Equipos</h5>

              <BusquedaXPlaca v-if="$config.countryFlag!=='EC'" :seleccionarEquipo="seleccionarEquipo"
                              origen="PRIMERA_POSICION"/>

              <p v-if="!equipos.length">
                No se recuperaron equipos fríos en el cliente, por favor póngase
                en contacto con el Administrador
              </p>
              <DetalleEquipoLinea :equipos="equipos" @update-id-equipos="updateIdEquipos"
                                  :selectedIdEquipos="idEquipos"/>

            </div>

            <div>
              <div class="mt-4 ml-4">
                <h5 class="card-title">Atender requerimiento</h5>
                <div class="form-check">
                  <input
                      class="form-check-input ml-1"
                      type="radio"
                      id="ejecutado"
                      value="EJECUTADO"
                      v-model="requerimiento"
                      @click="seleccionarRequerimiento"
                  />
                  <label class="form-check-label" for="ejecutado">
                    EJECUTADO
                  </label>
                </div>
                <div class="form-check">
                  <input
                      class="form-check-input ml-1"
                      type="radio"
                      id="fallido"
                      value="FALLIDO"
                      v-model="requerimiento"
                      @click="seleccionarRequerimiento"
                  />
                  <label class="form-check-label" for="fallido">
                    FALLIDO
                  </label>
                </div>
                <div class="form-group" v-if="mostrarMotivo">
                  <label>Motivo</label>
                  <select class="form-control" v-model="motivo">
                    <option value="" disabled>Seleccione un motivo</option>
                    <option value="DIRECCIÓN NO ENCONTRADA">
                      DIRECCIÓN NO ENCONTRADA
                    </option>
                    <option value="EQUIPO NO ENCONTRADO">
                      EQUIPO NO ENCONTRADO
                    </option>
                    <option value="OTRO MOTIVO">OTRO MOTIVO</option>
                  </select>
                </div>
                <h5 v-if="variable">Novedad</h5>
                <CustomVariableMultiple
                    :variable="variable"
                    :selectVariable="selectEjecutadoFallido"
                />

                <CustomAdjuntos
                    :tipoSolicitud="
                  solicitudProveedorSeleccionada.solicitud.tipoSolicitud
                "
                    :uploadFile="uploadFile"
                    columna="12"
                />

                <div class="form-group">
                  <label for="exampleFormControlTextarea1">Observación</label>
                  <textarea
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      v-model="observacion"
                  ></textarea>
                </div>
                <button
                    type="button"
                    class="btn btn-primary btn-block mt-3"
                    data-toggle="modal"
                    data-target="#exampleModal"
                    @click="confirmar"
                    :disabled="!archivosArray?.length"
                >
                  <i class="ti-check-box"></i>
                  Finalizar requerimiento
                </button>
              </div>
            </div>
          </form>
        </div>

        <Modal :mensaje="mensaje" :method="procesar"/>

        <ModalMessages
            :mensaje="mensajeProceso"
            :titulo="titulo"
            :method="cerrar"
        />
        <AgregarActividadZone
            titulo="Agregar actividad"
            :agregar="agregarActividad"
            origen="TECNICO"
        />
      </div>
    </div>
  </div>
</template>
<script>
import ModalMessages from "../../../components/ModalMessages.vue";
import Modal from "../../../components/Modal.vue";
import CabeceraRequerimiento from "./CabeceraRequerimiento.vue";
import CustomVariableMultiple from "@/components/CustomVariableMultiple.vue";
import BusquedaXPlaca from "@/components/BusquedaXPlaca.vue";
import AgregarActividadZone from "@/components/AgregarActividadZone.vue";
import DetalleEquipoLinea from "@/components/DetalleEquipoLinea.vue";
import CustomAdjuntos from "@/components/CustomAdjuntos.vue";

export default {
  components: {
    CustomAdjuntos,
    DetalleEquipoLinea,
    AgregarActividadZone,
    BusquedaXPlaca,
    ModalMessages,
    CabeceraRequerimiento,
    Modal,
    CustomVariableMultiple,
  },
  data() {
    return {
      observacion: "",
      idEquipos: [],
      requerimiento: "",
      mostrarMotivo: false,
      motivo: "",
      mensaje: "",
      mensajeProceso: null,
      titulo: "",
      ejecutarCerrar: false,
      tipoTrabajo: "",
      tipoProceso: "",
      mostrarRequerimiento: false,
      infoAdicional: [],
      variable: null,
      archivosArray: [],
    };
  },
  created() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$store.dispatch("auth/logout");
      return false;
    }
    this.$store.commit("equipos/setEquipos", null);
  },
  mounted() {
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    seleccionarRequerimiento(e) {
      if (e.target.value === "FALLIDO") {
        this.mostrarMotivo = true;
        this.variable = `FALLIDO_MANTENIMIENTO`;
      } else {
        this.mostrarMotivo = false;
        this.variable = `EJECUTADO_MANTENIMIENTO`;
      }
    },

    confirmar() {
      this.mensaje = "¿Desea continuar con el proceso de cambio primera posición?";
    },
    async procesar() {
      let errores = [];

      if (this.requerimiento === "FALLIDO") {
        if (this.infoAdicional.length === 0) {
          errores.push("Debe ingresar información adicional para un requerimiento FALLIDO.");
        }
      } else if (this.requerimiento === "EJECUTADO") {
        if (!this.solicitudProveedorSeleccionada.id) {
          errores.push("Debe seleccionar un proveedor para un requerimiento EJECUTADO.");
        }
        if (this.idEquipos.length === 0) {
          errores.push("Debe seleccionar equipos en un requerimiento EJECUTADO.");
        }
        if (this.infoAdicional.length === 0) {
          errores.push("Debe ingresar información adicional en un requerimiento EJECUTADO.");
        }
      } else {
        if (this.requerimiento === "") {
          errores.push("El requerimiento no puede estar vacío.");
        }
      }

      if (errores.length > 0) {
        this.mensajeProceso = [
          "Por favor ingrese toda la información requerida antes de continuar con el proceso de cambio",
        ];
        this.titulo = "Advertencia";
        this.ejecutarCerrar = false;
        document.getElementById("modalButton").click();
        return;
      }

      const formData = new FormData();
      this.archivosArray?.map((item) => formData.append("file", item));
      formData.append("estadoSolicitud", this.requerimiento);
      formData.append(
          "motivo",
          this.requerimiento === "EJECUTADO" ? "" : this.motivo
      );
      formData.append("infoAdicional", this.infoAdicional);
      formData.append(
          "idSolicitudProveedor",
          this.solicitudProveedorSeleccionada.id
      );
      formData.append(
        "idEquipoFrio",
        this.requerimiento === "EJECUTADO"
          ? this.idEquipos
          : [1]
      );
      formData.append("observacion", this.observacion);

      try {
        this.loaderSpinner();
        const resp = await this.$store.dispatch(
            "solicitudesProveedor/procesarPrimeraPosicion",
            {
              data: formData,
            }
        );
        this.mensajeProceso = [resp];
        this.titulo = "Primera Posición";
        this.ejecutarCerrar = true;
        document.getElementById("modalButton").click();
      } catch (error) {
        this.mensaje = error;
      } finally {
        this.loader.hide();
      }
    },
    cerrar() {
      if (this.ejecutarCerrar) {
        this.$router.go(-1);
      }
    },
    seleccionarEquipo(datos) {
      if (datos.tipo === "ADD") {
        this.idEquipos.push(datos.valor);
      }
      if (datos.tipo === "DELETE") {
        const idEquiposFiltrados = this.idEquipos.filter(
            (item) => item !== datos.valor
        );
        this.idEquipos = idEquiposFiltrados;
      }
    },
    async agregarActividad(data) {
      try {
        this.loaderSpinner();
        const formData = new FormData();
        data?.archivosArray?.map((item) => formData.append("file", item));
        formData.append(
            "equipo",
            data?.tipo === "SOLICITUD" ? 0 : data?.idEquipos[0]
        );
        formData.append("observacion", data?.observacion);
        formData.append("tipoActividad", data?.tipo);
        formData.append("tipoCorrectivo", data?.tipoTrabajo);
        formData.append(
            "solicitud",
            this.solicitudProveedorSeleccionada?.solicitud?.id
        );
        const resp = await this.$store.dispatch(
            "actividadesMantenimiento/crearActividad",
            {
              data: formData,
              idSolicitud:
              this.solicitudProveedorSeleccionada?.solicitud?.id,
            }
        );
        if (resp[0] === "ERROR") {
          this.mensajeProceso = [resp[1]];
          this.titulo = "Actividades";
          this.ejecutarCerrar = false;
          document.getElementById("modalButton").click();
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    },
    selectEjecutadoFallido(infoAdicional) {
      this.infoAdicional = infoAdicional;
    },
    updateIdEquipos(newIdEquipos) {
      this.idEquipos = [...new Set(newIdEquipos)];
    },
    uploadFile(file) {
      const selectedFile = file?.target?.files[0];
      if (!selectedFile) return;
      const findedFile = this.archivosArray?.findIndex(
          (item) => item.name === selectedFile.name
      );

      let arraySelectedFiles = [];
      if (findedFile === -1) {
        arraySelectedFiles = [...this.archivosArray, selectedFile];
      } else {
        arraySelectedFiles = [...this.archivosArray];
      }
      this.archivosArray = arraySelectedFiles;
    },
  },
  computed: {
    solicitudProveedorSeleccionada() {
      return this.$store.getters[
          "solicitudesProveedor/solicitudProveedorSeleccionada"
          ]
          ? this.$store.getters[
              "solicitudesProveedor/solicitudProveedorSeleccionada"
              ]
          : [];
    },
    isLogged() {
      return this.$store.getters["auth/isLogged"]
          ? this.$store.getters["auth/isLogged"]
          : false;
    },
    equipos() {
      return this.$store.getters["equipos/equipos"]
          ? this.$store.getters["equipos/equipos"]
          : [];
    },
  },
};
</script>
